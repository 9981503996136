.profile-page {
    .card {
        width: 100%;
        padding: 20px 40px 60px 40px;
    }
    .main-heading {
        text-align: center;
        margin: 20px 0px 40px 0px;
    }
    
    .forgot-pass-links {
         margin: 20px 0px;
         text-align: center !important;
    }
}