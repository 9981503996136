.tier-information {
	position: fixed;
	bottom: 0;
	background-color: #fff;
	span,
	p {
		text-transform: uppercase;
	}
	.c-stepper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0px;
	}

	.c-stepper__item {
		flex: 1;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.c-stepper__item:before {
		--size: 2rem;
		content: attr(title);
		position: relative;
		z-index: 1;
		display: block;
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		margin: 0.2rem auto 0;
		background-color: #fff;
		margin-bottom: 0;
	}
	.c-stepper__item_active:before {
		background-color: rgb(52, 12, 160);
		color: #fff;
	}
	.c-stepper__item:not(:last-child):after {
		content: "";
		position: relative;
		top: 1.25rem;
		left: 50%;
		height: 2px;
		background-color: #fff;
		order: -1;
	}
	.c-stepper__item_active:after {
		background-color: rgb(52, 12, 160);
	}
}
