a {
	color: #000;
}

.history-card {
	padding: 10px 16px;
}

/* header */

/* navigation */
.navigation-container {
	/* display: flex; */
}
.top-left {
	flex: 1;
}
.top-right {
    flex:1;
  }
.top-navigation {
	flex: 6;
}
.top-header-left {
	display: flex;
	width: 25%;
	justify-content: center;
}
.top-header-middle {
	width: 50%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.top-header-right {
	display: flex;
	width: 25%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.logo img {
	padding: 20px 0px;
}
.top-navigation a {
	text-decoration: none;
}

/* nav {
    font-family: monospace;
  } */

.top-navigation ul {
	background: rgb(238, 235, 235);
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.top-navigation li {
	color: #000;
	background: rgb(238, 235, 235);
	display: block;
	float: left;
	padding: 1rem;
	position: relative;
	text-decoration: none;
	transition-duration: 0.5s;
}

.top-navigation li a {
	color: #000;
}

.top-navigation li:hover,
li:focus-within {
	/* background: rgb(70, 64, 64); */
	cursor: pointer;
}

.top-navigation li:focus-within a {
	outline: none;
}

.top-navigation ul li ul {
	z-index: 99;
	background: #ccc;
	visibility: hidden;
	opacity: 0;
	min-width: 5rem;
	position: absolute;
	transition: all 0.5s ease;
	margin-top: 1rem;
	left: 0;
	display: none;
}

.top-navigation ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
	visibility: visible;
	opacity: 1;
	display: block;
}

.top-navigation ul li ul li {
	clear: both;
	width: 200px;
}

.portal-point-list-container .label,
.header-parent-label {
	font-weight: 600;
	font-size: 25px;
	margin: 30px 0px;
	border-bottom: 2px solid #2d2a2a;
}
.portal-point-list .activity-block {
	cursor: pointer;
}
.portal-point-list .activity-block:hover {
	background-color: #fff;
	color: #000;
}
.portal-point-list .activity-block .cart-button {
	width: 85%;
	text-align: center;
}
.container-card-btn {
	text-align: center;
}
.portal-point-list .activity-block .cart-button:hover {
	font-weight: bolder;
}
.portal-history .point-history-label {
	text-align: center;
	margin: 5%;
}
.nav-item-div {
	width: 100%;
	text-align: center;
	padding: 10px;
	background-color: #fff;
	border-bottom: 1px solid #000;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.top-left img {
		width: 50px;
		height: 50px;
	}
	.top-right {
		display: none;
	}
	.top-navigation {
		flex-basis: 100%;
		flex-grow: 1;
	}
	.portal-point-list-container .label {
		font-size: 18px;
	}
	.portal-point-list .title,
	.portal-point-list .points-actions {
		font-size: 18px;
	}
}
.table-report-div .search-label {
	width: 70%;
	padding: 20px;
}
.eligible-product-list-tooltrip-span {
	padding: 0px 5px 0px 5px;
	color: #fff;
	background-color: black;
	border-radius: 50%;
}
.nav-item-custom {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
	color: rgba(0, 0, 0, 0.5) !important;
}
.nav-link-custom {
	padding: 0px !important;
}
#headerParentLink:after {
	content: " \00BB";
}
.rdw-editor-main {
	height: 100%;
	min-height: 200px !important;
	overflow: auto;
	box-sizing: border-box;
}
.rdw-editor-wrapper {
	border: 1px solid #f1f1f1 !important;
	box-sizing: content-box;
}

.dropzone-div {
	padding: 5%;
	margin: 2%;
	border: #ccc dashed;
}
.dropzone-div .placeholder {
	align-items: center;
	border-radius: 5px;
	display: flex;
	height: 140px;
	justify-content: center;
	margin: auto;
	max-width: 180px;
	min-height: 140px;
	min-width: 180px;
	padding: 0px;
	position: relative;
	background-image: linear-gradient(0deg, #ededed, #efefef, #f1f1f1, #f4f4f4, #f6f6f6);
}
.dropzone-div .placeholder .closeHref {
	width: 20px;
	height: 20px;
	float: right;
}
.dropzone-div .placeholder .closeHref {
	position: absolute;
	top: 5px;
	right: 10px;
	z-index: 950;
}
.dropzone-div .placeholder .closeHref img {
	width: 20px;
	display: none;
}
.dropzone-div .placeholder:hover .closeImg {
	display: block;
	background-color: #fff;
	border-radius: 50%;
}
/* // Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { ... }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { ... }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { ... } */
