@font-face {
    font-family: "Gotham-Bold";
    src: url("/public/fonts/Gotham Bold.otf") format("truetype");
}

@font-face {
    font-family: "Gotham-Light";
    src: url("/public/fonts/Gotham Light.otf") format("truetype");
}

/*
1) Imported Custom Fonts
*/

@font-face {
    font-family: 'NeutraText-Bold';
    src: url("/public/fonts/NeutraText-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'NeutraText-BoldItalic';
    src: url("/public/fonts/NeutraText-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: 'NeutraText-Regular';
    src: url("/public/fonts/NeutraText-Book.otf") format("opentype");
}

@font-face {
    font-family: 'NeutraText-RegularItalic';
    src: url("/public/fonts/NeutraText-BookItalic.otf") format("opentype");
}

html {
    scroll-behavior: smooth
}

body {
    font-family: "NeutraText-Regular";
    font-size: 20px;
    font-weight: normal;
    color: #000;
    background-color: #fff;
}

.skin-size {
    max-width: 1235px;
    margin: auto;
    /* text-align: center; */
}

.side-color {
    background-color: #f5f5f5;
}

.white {
    color: #fff;
}

.h1,
h1 {
    font-family: "NeutraText-Regular";
    font-size: 32px;
    text-align: center;
    color: #fcb117;
}

h2 {
    font-family: "NeutraText-Regular";
    font-size: 22px;
    font-weight: bold;
    color: #126ab4;
}

.bold {
    font-weight: 500;
}

.f18 {
    font-size: 18px;
}

.f22 {
    font-size: 22px;
}

.f22 button {
    font-size: 22px !important;
    font-weight: 900;
}

.main-nav-center {
    flex: 6;
}

.navbar {
    background: white;
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25);
    padding-top: 0;
    padding-bottom: 0;
    height: 85px;
    border-bottom: 5px solid #2D2D75;
}

.navbar-brand {
    padding: 0;
    max-height: 76px;
}

.navbar-brand img {
    padding: 2px;
    width: auto;
    height: 100%;
    min-width: 69px;
}

.navbar-light .navbar-nav .nav-link {
    font-family: "NeutraText-Regular";
    color: #000 !important;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 50px;
    text-decoration: none;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.nav-link {
    padding: 0.5rem 1rem 0.5rem !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.navbar-light .navbar-nav .nav-link {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    background: transparent;
    border: 0;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #000;
    border-bottom: none;
    outline: none !important;
}

.nav-link-custom .nav-item-custom {
    color: #000;
}

ul.dropdown-menu {
    padding: 0;
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25);
}

.navbar-nav .dropdown-menu {
    border: 2px solid #000000;
    border-radius: 0;
}

.navbar-divide-bot {
    border-bottom: 2px solid #000000;
}

.navbar-divide-top {
    border-top: 2px solid #000000;
    margin-top: 2px
}

.navbar-nav .dropdown-menu .dropdown-item {
    padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #fff;
    color: #000;
}

.dropdown-toggle::after {
    display: none;
}

.user-menu-icon {
    border: 0;
    background: none;
}

.userprofile-icon {
    width: 40px;
    vertical-align: text-bottom;
}

.dd-item-icon {
    width: 32px;
    padding-right: 6px;
}

.user-menu ul.dropdown-menu {
    padding-bottom: 15px;
}

.user-menu .dropdown-item a,
.user-menu .dropdown-item button {
    text-decoration: none;
    display: block;
    text-align: left;
    color: #000000;
    line-height: 24px;
    padding-left: 0;
    width: 100%;
}

.user-menu .dropdown-menu button.btn-profile {
    padding: 0;
    outline: none;
    border: none;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
    display: none;
}

.asterisk:after {
    content: "*";
    width: 5px;
    height: 5px;
    color: #606060;
    font-weight: bold;
}

.visible-hidden {
    opacity: 0;
    display: none;
}

.v-align-baseline {
    align-items: baseline;
}

.form-group {
    display: block;
    margin-bottom: 30px;
    /* text-transform: capitalize !important; */
}

.col-form-label {
    font-family: "NeutraText-Regular";
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    /* text-transform: capitalize !important; */
}

.form-group .form-control {
    background: transparent;
    color: #606060;
    border: none;
    border-bottom: 3px solid #606060;
    height: 36px;
    border-radius: 0px;
    font-size: 16px;
    font-family: "NeutraText-Regular";
    padding: 5px 15px 5px 0;
    outline: 0;
}

.form-control:focus {
    border-color: #000;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.form-group .form-control::placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 300 !important;
}

.form-group .show-placegholder::placeholder {
    color: #bebebe !important;
    font-weight: 300 !important;
}

.agreewith {
    margin-top: 0.5rem;
    margin-left: 10px;
}

.main-heading {
    margin: 20px 0px 50px 0px !important;

}

.htp-heading {
    font-family: "NeutraText-Bold";
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: #126ab4;
}

.sub-heading {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 60px;

}

.sub-heading a {
    text-decoration: underline;
}

.skiptocontent {
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 0;
    transition: -webkit-transform .166s;
    transition: transform .166s;
    transition: transform .166s, -webkit-transform .166s;
    -webkit-transform: translate(-50%, -110%);
    -ms-transform: translate(-50%, -110%);
    transform: translate(-50%, -110%);
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.btn-primary {
    font-family: "NeutraText-Regular";
    margin-top: 20px;
    background: #24ACDC;
    /*text-transform: capitalize !important;
    */
    color: #FFFFFF;
    font-size: 18px;
    line-height: 15px;
    border-radius: 0px;
    border: 1px solid #000000;
    min-width: 160px;
    letter-spacing: 1px;
    padding: 20x 20px;
    text-decoration: none;
    border-width: 1px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
    background: #ffffff !important;
    color: #000000;
    border: 1px solid #000000;
}
.btn-primary:disabled {
    background: #24ACDC !important;
    color: #ffffff;
}
.control-disabled:disabled {
    color: #666 !important;
}


.btn-link {
    color: #000000;
    text-decoration: underline;
    font-size: 18px;
}

a {
    color: #000000;
    text-decoration: underline;
}

a:hover,
a:focus {
    color: #000;
    text-decoration: none;
}

.footer-link li:focus-within {
    background: none;
}

.btn-light {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    transition: ease 0.3s;
}

.btn-light:hover,
.btn-light:focus {
    background-color: #000000;
    color: #fff;
}

.upload-note {
    font-family: 'NeutraText-Bold';
    font-size: 3rem;
    font-weight: bold;
    font-family: NeutraText-Bold;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center !important;
    font-weight: bold !important;
}

p table {
    border-collapse: collapse !important;
}

th,
td {
    padding: 3px 5px;
    border: 1px solid Black !important;
}

th {
    font-weight: bold !important;
}

.product-header {
    font-weight: bold !important;
    color: #0a175b;
}

.faq-accordion {
    margin: 0 !important;
}

.accordion .card {
    margin-bottom: 0px !important;
}

.card {
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    padding: 20px;
}

.faq-page {
    padding: 0px !important;
    margin-bottom: 25px;
}

.faq-page .card {
    padding: 0;
    /* margin: 1px 0; */
    border: none;
    border-radius: 0;
    background: #26b7e3;
    color: #000000;
    font-family: "NeutraText-Regular";
}

.faq-page .card-header {
    padding: 5px 15px
}

.faq-page .nav-link {
    /* padding: 20px 0 !important; */
}

.faq-line-icon {
    color: #fff;
    list-style: 32px;
    font-size: 22px;
    font-weight: bold;
}

.faq-page .card-body {
    font-family: "NeutraText-Regular";
    /* margin-bottom: 12px; */
    padding: 1rem 1.25rem;
    border: none;
    color: #000000;
    background-color: #fff;
    border-collapse: collapse;
    border: 1px solid #eee;
}

.same-height {
    display: flex;
}

.alink {
    color: 000000;
    text-decoration: underline;
}

.alink:hover,
.alink:focus {
    color: #000000;
    text-decoration: underline;
}

.white-link {
    color: #fff !important;
    text-decoration: underline;
}

.white-link:hover,
.white-link:focus {
    color: #fff;
    text-decoration: underline;
}

.remove-underline,
.remove-underline span {
    text-decoration: none !important;
    border: none !important;
    outline: none !important;
}

.remove-underline:hover,
.remove-underline span:hover,
.remove-underline span:focus {
    text-decoration: none !important;
    cursor: pointer;
}

*:focus {
    outline: 0 !important;
}

.remove-underline::-moz-focus-inner,
.remove-underline span::-moz-focus-inner {
    border: 0;
}

/* reward page */
.rewards-page .reward-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

.rewards-page-modal .form-group .form-control {
    border-bottom: none;
    padding-left: 15px;
    height: 46px;
}

.card-cta .card {
    padding: 0;
}

.card-cta .card:hover {
    background-color: #fff;
    border-color: #738eb6;
    cursor: pointer !important;
}

.card-cta img {
    border-radius: 20px;
    margin: 15px 0 0;
}

.card-p {
    padding: 10px 20px;
}


.thankspage {
    color: #000 !important;
}

.thankspage h2 {
    font-family: "NeutraText-Regular";
    font-size: 50px;
    font-weight: 200;
    text-align: center;
}

.thankspage a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #fff;
}

.thankspage a:hover,
.thankspage a:focus {
    color: #000;
}

/* footer */
footer {
    background-color: #126ab4;
    color: #fff;
    font-size: 18px;
}

.footer {
    padding-top: 32px;
    padding-bottom: 32px;
}

footer a {
    color: #fff;
    transition: 0.5s;
    text-decoration: none;
}

.footer-link li a {
    color: #fff;
    font-family: "NeutraText-Bold";
}

.footer-link li a:hover,
.footer-link li a:focus {
    color: #fff;
    text-decoration: underline;
}

.footer-logo_wrap {
    justify-content: center;
    display: flex;
}

.footer-logo_wrap img {
    width: 100px;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* .social-links{justify-content: left !important;}
.footer .footer-link{justify-content: left !important;} */
.footer .footer-link ul {
    padding-left: 0;
    text-align: center;
}

.footer .footer-link ul li {
    padding: 0 6px;
    display: inline-block;
    line-height: 24px;
    float: inherit;
}

.footer .footer-link ul li a[type=button] {
    color: #fff;
}

.footer .footer-link ul li a[type=button]:hover,
.footer .footer-link ul li a[type=button]:focus {
    color: #fff;
    text-decoration: none;
}


.footer-social li {
    padding: 0;
}

.footer-social li a {
    padding: 5px 15px;
    font-size: 22px;
    color: #fff;
}

.footer-social li a:hover,
.footer-social li a:focus {
    background: none;
}

.footer-bot {
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.15);
    padding-top: 12px;
}

button.poplink {
    color: #fff;
    font-size: 18px;
    padding: 0px 0 3px;
}

button.poplink:hover {
    color: #1ea8e0;
    text-decoration: none;
}

.footer-bot .footer-link {
    justify-content: right !important;
    color: #fff;
}

.copy-text {
    margin: 0 0 10px !important;
    text-align: left;
}


.tierlogic a {
    line-height: 30px;
}

.tierlogic img {
    max-height: 30px;
}

/* end footer */


.input-error {
    font-size: 18px;
    margin-top: 4px;
}

.portal-point-list-container {
    padding: 40px 0px 40px;
}

.portal-point-list-container .label,
.header-parent-label {
    color: #33333b;
    border-bottom: none;
    text-align: center;
    font-size: 35px;
}

.section-content {
    margin: 40px auto;
}

.section-heading {
    font-size: 3rem;
    font-weight: bold;
    font-family: "NeutraText-Bold";
}

.htp-content {
    margin-bottom: 30px;
}

.custom-tooltip-wrap .btn {
    min-width: 26px;
    padding: 0;
    height: 26px;
    border-radius: 80px;
    position: relative;
    left: 6px;
    font-size: 12px;
    margin: 0;
    top: -3px;
}

.inline-checkboxes {
    display: flex;
    align-items: flex-start;
}

.inline-checkboxes label {
    margin-top: -5px;
    margin-left: 10px;
}

.form-check-input {
    margin-top: 0.3rem !important;
    margin-left: -0.9rem;
    height: 16px;
    width: 16px;
    border-radius: 0;
}

.form-check-label {
    margin-left: 14px;
    color: #000000 !important;
}

ul.listing-points {
    margin: 20px 0;
    padding: 0;
}

ul.listing-points li {
    margin: 10px 0;
    padding: 5px 0px;
    text-align: justify;
}


/* after login */
.table-bordered td {
    border: none;
}

.table-bordered tbody tr:first-child td {
    padding-top: 30px;
}

.table-bordered tbody tr:last-child td {
    padding-bottom: 30px;
}

.mya_table table thead {
    background-color: #000000;
    color: #FFFFFF;
    font-weight: normal !important;
}

.pagination-block {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.pagination-block .pagination.react-bootstrap-table-page-btns-ul {
    float: none;
}

.page-item.disabled .page-link {
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #000000;
    border-color: #000000;
    text-decoration: none;
}

.pagination>.active>a:hover {
    background-color: #0e6eb4;
    border-color: #0e6eb4;
}

.pagination .disabled a {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination>li>a {
    border: 1px solid #000000;
}

.pagination .previous a {
    border-radius: 4px 0 0 4px !important;
}

.pagination .next a {
    border-radius: 0 4px 4px 0 !important;
}

.main-logo {
    position: absolute;
    left: 10px;
    top: 0px;
    z-index: 99;
    padding: 2px;
    height: 80px;
    /* max-width: 250px; */
}

@media screen and (max-width: 380px) {
    .main-logo {
        /* top: 15px; */
        max-width: 120px;
    }
}

@media screen and (max-width: 280px) {
    .main-logo {
        /* top: 25px; */
        max-width: 90px;
    }
}

.page-tab_nav a {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    min-width: 160px;
    padding: 10px 40px 10px;
}

.active-page {
    background: #000000 !important;
    color: #fff !important;
    border-radius: 4px;
}

.dropzone-div {
    background: #efeeee;
    padding-top: 50px;
    padding-bottom: 50px;
}

.modal-content {
    border-radius: 3rem;
    background-color: #126ab4;
    color: #fff;
    padding: 1.5rem .8rem;
    font-weight: normal;
}

.modal-content .btn-link {
    color: #fff;
}

.modal-heading {
    font-size: 22px;
}

.modal-content .btn-modal {
    background-color: #fff;
    color: #000000;
}

.modal-content a {
    color: #1ea8e0;
}

.modal-content .section-content {
    margin: 0 auto;
}

.close {
    color: #fff;
    opacity: .7;
    text-shadow: 0 1px 0 #000;
}

.close:hover {
    color: #D9D9D9;
}

ul.checkmark {
    margin: 0;
    padding: 0;
}

ul.checkmark li {
    font-size: 16px;
    margin-bottom: 1em;
    list-style-type: none;
    padding: .25em 0 0 2.5em;
    position: relative;
}

ul.checkmark li:before {
    content: " ";
    display: block;
    border: solid .8em #1ea8e0;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 35%;
    margin-top: -.5em;
}

ul.checkmark li:after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #fff;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1.2em;
    top: 38%;
    margin-top: -.2em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        margin-left: 50px;
    }
}



@media (min-width: 576px) {
    .mobile-hide {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .user-pos {
        position: absolute;
        right: 100px;
        top: 10px;
    }
}

.mob-menu-pos {
    float: right !important
}

@media (max-width: 575.98px) {
    .desktop-hide {
        display: none !important;
    }

    .page-tab_nav .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .page-tab_nav a {
        min-width: auto;
        padding: 10px;
        display: inline-block;
    }

    .btn-primary {
        min-width: 180px;
    }

    .upload-cta .btn-primary {
        min-width: 150px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-secondary {
        max-width: 100px !important;
        min-width: 100px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 35px;
    }

    .form-check-input {
        margin-top: 0.3rem !important;
        height: 20px;
        width: 20px;
    }


    .navbar {
        padding: 8px 0;
    }

    .navbar .navbar-toggler {
        margin-right: 16px;
    }

    .user-menu {
        margin-left: 16px;
        width: 100%;
    }

    .user-menu .dropdown-menu {
        min-width: 260px;
    }

    .user-pos {
        position: absolute;
        right: 80px;
        top: 10px;
    }

    .mob-menu-pos {
        float: right !important
    }
}

@media (max-width: 997px) {
    .upload-btn {
        display: block !important;
        margin: auto !important;
        width: 100% !important;
        text-align: center !important;
    }
}

.react-bootstrap-table table {
    table-layout: auto;
}

@media screen and (max-width: 991px) {
    #button-navbar-toggler {
        margin: 0 auto;
    }

    .navbar-light .navbar-nav .nav-link {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 24px !important;
        font-size: 15px;
        /* margin-left: 25px; */
        color: #fff;
    }

    .navbar-collapse {
        margin-top: 30px;
        background-color: white;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("/public/images/black-hamburger.png");

    }

    .navbar-toggler {
        border: 0px transparent !important;
        color: transparent !important;
    }
}

.contentinfo {
    background-color: #fff;
}

.footer-link ul {
    font-family: "NeutraText-Regular";
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
}

.copy-text {
    text-align: center;
}

.tierlogic {
    margin-bottom: 0px !important;
}

main {
    background-color: #fff;
}

.asterisk:after {
    color: red !important;
}
.orange{
    color: #f37d32 !important;
}